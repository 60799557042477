/**
 * Created by Admin on 08.05.2018.
 */

import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from './api'
import { saveAdminUser } from './tabs/authorization/actions/adminUser'
import { BASE_URL } from './configs'
import { nlpRoutes } from 'routes/nlpRoutes'
import * as FullStory from '@fullstory/browser'

export const ROLE = {
  ADMIN: 'ROLE_ADMIN',
  SIMPLE_ADMIN: 'ROLE_SIMPLE_ADMIN',
  MANAGER: 'ROLE_MANAGER',
  SUPPORT: 'ROLE_SUPPORT',
}

const getSimpleAdminAllowedNlpRoutes = () => {
  return nlpRoutes.filter(route => route.name !== 'Intents' && route.name !== 'Triggers')
}

export const ROLE_OPTIONS = [
  {
    value: ROLE.ADMIN,
    label: 'Admin',
    features: [
      'support',
      'dashboard',
      'flows',
      'atomBuilder',
      'settings',
      'nlp',
      'broadcast',
      'widget',
      'createBot',
      'shareBot',
      'analytics',
      'copilot',
      'nlpSettings',
    ],
    nlpRoutes: nlpRoutes,
  },
  {
    value: ROLE.SIMPLE_ADMIN,
    label: 'Simple admin',
    features: [
      'support',
      'dashboard',
      'flows',
      'atomBuilder',
      'settings',
      'nlp',
      'widget',
      'createBot',
      'shareBot',
      'analytics',
      'copilot',
      'nlpSettings',
    ],
    nlpRoutes: getSimpleAdminAllowedNlpRoutes(),
  },
  {
    value: ROLE.MANAGER,
    label: 'Manager',
    features: ['support', 'dashboard', 'flows', 'atomBuilder', 'nlp', 'widget', 'analytics', 'copilot'],
    nlpRoutes: getSimpleAdminAllowedNlpRoutes(),
  },
  {
    value: ROLE.SUPPORT,
    label: 'Support',
    features: ['support', 'dashboard', 'analytics'],
  },
]

export const access = {
  userRole: null,
  botAccesses: [],

  loadRole() {
    fetch(BASE_URL + '/getAdminUser', {
      credentials: 'include',
      method: 'GET',
      headers: createJsonHeaders(),
    })
      .then(response => logoutOnRequestOrResponseJson(response))
      .then(json => {
        access.userRole = json.role
        access.botAccesses = json.botAccesses
        saveAdminUser(json)
        if (process.env.REACT_APP_FULL_STORY_ORG_ID) {
          FullStory.identify(json.id, { email: json.email })
        }
        return json
      })
      .catch(function (error) {
        alertError('Sorry but something is going wrong please ping support!')
      })
  },
}

export function hasPermissionForBot(botId, featureName) {
  const botAccess = access?.botAccesses?.filter(ba => ba.botId === Number(botId))[0]

  return botAccess && ROLE_OPTIONS?.find(role => role.value === botAccess.role)?.features?.includes(featureName)
}

export function hasPermissionForFeature(featureName) {
  const adminHigherRole = findHighestRole(access?.botAccesses)

  return ROLE_OPTIONS?.find(role => role.value === adminHigherRole)?.features?.includes(featureName)
}

function findHighestRole(botAccesses) {
  const sortedRoles = botAccesses.map(botAccess => botAccess.role).sort(higherRolesFirst)
  return sortedRoles[0]
}

function higherRolesFirst(firstRole, secondRole) {
  const allRoles = ['ROLE_ADMIN', 'ROLE_SIMPLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPPORT']
  for (const role of allRoles) {
    if (firstRole === role) {
      return -1
    }
    if (secondRole === role) {
      return 1
    }
  }
  return 0
}
